<template>
  <div class="body">
    <titles>地图</titles>
    <img src="../assets/图像.png" />
    <div class="success">
      <div class="commonCss">
        <div class="title">一、邻里场景</div>
        <div
          class="text"
          @mouseover="mouseover('one')"
          @mouseleave="mouseleave('one')"
        >
          <div class="num">1</div>
          <div class="word">邻里中心</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('two')"
          @mouseleave="mouseleave('two')"
        >
          <div class="num">2</div>
          <div class="word">南官河文化公园</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('three')"
          @mouseleave="mouseleave('three')"
        >
          <div class="num">3</div>
          <div class="word">社区入口广场</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('four')"
          @mouseleave="mouseleave('four')"
        >
          <div class="num">4</div>
          <div class="word">未来生活体验中心</div>
        </div>
        <div class="title">二、教育场景</div>
        <div
          class="text"
          @mouseover="mouseover('five')"
          @mouseleave="mouseleave('five')"
        >
          <div class="num" style="background-color: #d44eb1">5</div>
          <div class="word">河西小学</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('six')"
          @mouseleave="mouseleave('six')"
        >
          <div class="num" style="background-color: #d44eb1">6</div>
          <div class="word">托育中心</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('seven')"
          @mouseleave="mouseleave('seven')"
        >
          <div class="num" style="background-color: #d44eb1">7</div>
          <div class="word">幸福学堂</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('eight')"
          @mouseleave="mouseleave('eight')"
        >
          <div class="num" style="background-color: #d44eb1">8</div>
          <div class="word">无人共享书屋</div>
        </div>
        <div class="title">三、健康场景</div>
        <div
          class="text"
          style="margin-top: 0.644vw"
          @mouseover="mouseover('nine')"
          @mouseleave="mouseleave('nine')"
        >
          <div class="num" style="background-color: #71c330">9</div>
          <div class="word">社区健身空间</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('ten')"
          @mouseleave="mouseleave('ten')"
        >
          <div class="num" style="background-color: #71c330">10</div>
          <div class="word">社区卫生服务中心</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('eleven')"
          @mouseleave="mouseleave('eleven')"
        >
          <div class="num" style="background-color: #71c330">11</div>
          <div class="word">室外运动点</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('twelve')"
          @mouseleave="mouseleave('twelve')"
        >
          <div class="num" style="background-color: #71c330">12</div>
          <div class="word">健康小屋</div>
        </div>
        <div class="title">四、创业场景</div>
        <div
          class="text"
          style="margin-top: 0.644vw"
          @mouseover="mouseover('thirteen')"
          @mouseleave="mouseleave('thirteen')"
        >
          <div class="num" style="background-color: #8597b0">13</div>
          <div class="word">创想工作室</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('fourteen')"
          @mouseleave="mouseleave('fourteen')"
        >
          <div class="num" style="background-color: #8597b0">14</div>
          <div class="word">人才公寓</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('fifteen')"
          @mouseleave="mouseleave('fifteen')"
        >
          <div class="num" style="background-color: #8597b0">15</div>
          <div class="word">双创中心</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('sixteen')"
          @mouseleave="mouseleave('sixteen')"
        >
          <div class="num" style="background-color: #8597b0">16</div>
          <div class="word">创业创新服务中心</div>
        </div>
      </div>
      <div class="commonCss" style="margin-left: 0.644vw">
        <div class="title">五、建筑场景</div>
        <div
          class="text"
          @mouseover="mouseover('seventeen')"
          @mouseleave="mouseleave('seventeen')"
        >
          <div class="num" style="background-color: #bf9000">17</div>
          <div class="word">河西巷</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('eighteen')"
          @mouseleave="mouseleave('eighteen')"
        >
          <div class="num" style="background-color: #bf9000">18</div>
          <div class="word">河西小学</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('nineteen')"
          @mouseleave="mouseleave('nineteen')"
        >
          <div class="num" style="background-color: #bf9000">19</div>
          <div class="word">大量建筑符合利用</div>
        </div>
        <div class="title" style="margin-top: 2.252vw">六、交通场景</div>
        <div
          class="text"
          @mouseover="mouseover('twenty')"
          @mouseleave="mouseleave('twenty')"
        >
          <div class="num" style="background-color: #000">20</div>
          <div class="word">智慧停车场</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('twentyOne')"
          @mouseleave="mouseleave('twentyOne')"
        >
          <div class="num" style="background-color: #000">21</div>
          <div class="word">公交站点</div>
        </div>
        <div class="title" style="margin-top: 1.931vw">七、低碳场景</div>
        <div
          class="text"
          @mouseover="mouseover('twentyTwo')"
          @mouseleave="mouseleave('twentyTwo')"
        >
          <div class="num" style="background-color: #2f5597">22</div>
          <div class="word">海绵公园</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('twentyThree')"
          @mouseleave="mouseleave('twentyThree')"
        >
          <div class="num" style="background-color: #2f5597">23</div>
          <div class="word">超低能耗建筑</div>
        </div>
        <div class="title" style="margin-top: 2.252vw">八、服务场景</div>
        <div
          class="text"
          @mouseover="mouseover('twentyFour')"
          @mouseleave="mouseleave('twentyFour')"
        >
          <div class="num" style="background-color: #548235">24</div>
          <div class="word">河西巷（十里长街）</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('twentyFive')"
          @mouseleave="mouseleave('twentyFive')"
        >
          <div class="num" style="background-color: #548235">25</div>
          <div class="word">河西潮街</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('twentySix')"
          @mouseleave="mouseleave('twentySix')"
        >
          <div class="num" style="background-color: #548235">26</div>
          <div class="word">社区邻里综合体</div>
        </div>
        <div class="title" style="margin-top: 0.965vw">九、治理场景</div>
        <div
          class="text"
          @mouseover="mouseover('twentySeven')"
          @mouseleave="mouseleave('twentySeven')"
        >
          <div class="num" style="background-color: #7030a0">27</div>
          <div class="word">综合服务大厅</div>
        </div>
        <div
          class="text"
          @mouseover="mouseover('twentyEight')"
          @mouseleave="mouseleave('twentyEight')"
        >
          <div class="num" style="background-color: #7030a0">28</div>
          <div class="word">党群服务中心</div>
        </div>
      </div>
    </div>
    <div class="p">
      <!-- <p
        style="top: 13.514vw; left: 24.003vw; background-color: #111"
        :class="twentyOne ? 'active' : ''"
      >
        21
      </p> -->
      <!-- <span style="top: 13.385vw; left: 25.869vw" v-if="twentyOne"
        >公交站点</span
      > -->
      <p
        style="top: 11.712vw; left: 28.121vw; background-color: #2f5597"
        :class="twentyTwo ? 'active' : ''"
      >
        22
      </p>
      <span style="top: 11.455vw; left: 29.858vw" v-if="twentyTwo"
        >海绵公园</span
      >
      <p
        style="top: 13.192vw; left: 33.14vw; background-color: #2f5597"
        :class="twentyThree ? 'active' : ''"
      >
        23
      </p>
      <span style="top: 12.999vw; left: 34.877vw" v-if="twentyThree"
        >超低能耗建筑</span
      >
      <p
        style="top: 15.701vw; left: 28.314vw; background-color: #66bf1f"
        :class="eleven ? 'active' : ''"
      >
        11
      </p>
      <span style="top: 15.444vw; left: 30.051vw" v-if="eleven"
        >室外运动点</span
      >
      <p
        style="top: 16.924vw; left: 29.923vw; background-color: #66bf1f"
        :class="ten ? 'active' : ''"
      >
        10
      </p>
      <span style="top: 16.667vw; left: 31.725vw" v-if="ten"
        >社区卫生服务中心</span
      >
      <p
        style="top: 17.954vw; left: 29.408vw; background-color: #66bf1f"
        :class="twelve ? 'active' : ''"
      >
        12
      </p>
      <span style="top: 17.761vw; left: 31.21vw" v-if="twelve">健康小屋</span>
      <p
        style="top: 20.142vw; left: 31.725vw; background-color: #66bf1f"
        :class="twelve ? 'active' : ''"
      >
        12
      </p>
      <span style="top: 19.82vw; left: 33.656vw" v-if="twelve">健康小屋</span>
      <p
        style="top: 24.131vw; left: 28.378vw; background-color: #66bf1f"
        :class="eleven ? 'active' : ''"
      >
        11
      </p>
      <span style="top: 23.874vw; left: 30.115vw" v-if="eleven"
        >室外运动点</span
      >
      <p
        style="top: 19.562vw; left: 27.928vw; background-color: #dc70bf"
        :class="eight ? 'active' : ''"
      >
        8
      </p>
      <span style="top: 19.305vw; left: 29.601vw" v-if="eight"
        >无人共享书屋</span
      >
      <p
        style="top: 25.032vw; left: 27.349vw; background-color: #8497b1"
        :class="thirteen ? 'active' : ''"
      >
        13
      </p>
      <span style="top: 24.775vw; left: 29.086vw" v-if="thirteen"
        >创想工作室</span
      >
      <p
        style="top: 26.255vw; left: 30.888vw; background-color: #548235"
        :class="twentyFive ? 'active' : ''"
      >
        25
      </p>
      <span style="top: 25.998vw; left: 32.625vw" v-if="twentyFive"
        >河西潮街</span
      >
      <p
        style="top: 14.672vw; left: 38.546vw; background-color: #c00000"
        :class="two ? 'active' : ''"
      >
        2
      </p>
      <span style="top: 14.415vw; left: 40.283vw" v-if="two"
        >南官河文化公园</span
      >
      <p
        style="top: 16.216vw; right: 20.142vw; background-color: #111"
        :class="twenty ? 'active' : ''"
      >
        20
      </p>
      <span style="top: 15.959vw; right: 11.937vw" v-if="twenty"
        >智慧停车场</span
      >
      <p
        style="top: 24.131vw; right: 20.142vw; background-color: #111"
        :class="twenty ? 'active' : ''"
      >
        20
      </p>
      <span style="top: 23.874vw; right: 11.737vw" v-if="twenty"
        >智慧停车场</span
      >
      <p
        style="top: 15.058vw; left: 42.471vw; background-color: #548235"
        :class="twentyFour ? 'active' : ''"
      >
        24
      </p>
      <span style="top: 14.865vw; left: 44.273vw" v-if="twentyFour"
        >河西巷（十里长街）</span
      >
      <p
        style="top: 14.35vw; left: 44.981vw; background-color: #8497b0"
        :class="thirteen ? 'active' : ''"
      >
        13
      </p>
      <span style="top: 14.093vw; left: 46.718vw" v-if="thirteen"
        >创想工作室</span
      >
      <p
        style="top: 14.801vw; left: 47.362vw; background-color: #bf9000"
        :class="seventeen ? 'active' : ''"
      >
        17
      </p>
      <span style="top: 14.672vw; left: 49.164vw" v-if="seventeen">河西巷</span>
      <p
        style="top: 18.211vw; left: 42.986vw; background-color: #66bf1f"
        :class="eleven ? 'active' : ''"
      >
        11
      </p>
      <span style="top: 18.082vw; left: 44.723vw" v-if="eleven"
        >室外运动点</span
      >
      <p
        style="top: 20.399vw; left: 43.758vw; background-color: #66bf1f"
        :class="eleven ? 'active' : ''"
      >
        11
      </p>
      <span style="top: 20.142vw; left: 45.495vw" v-if="eleven"
        >室外运动点</span
      >
      <p
        style="top: 22.008vw; left: 41.184vw; background-color: #66bf1f"
        :class="twelve ? 'active' : ''"
      >
        12
      </p>
      <span style="top: 21.815vw; left: 42.921vw" v-if="twelve">健康小屋</span>
      <p
        style="top: 21.399vw; left: 44.758vw; background-color: #d54eb1"
        :class="six ? 'active' : ''"
      >
        6
      </p>
      <span style="top: 21.206vw; left: 46.56vw" v-if="six">托育中心</span>
      <p
        style="top: 21.785vw; left: 47.782vw; background-color: #d54eb1"
        :class="five ? 'active' : ''"
      >
        5
      </p>
      <span style="top: 21.656vw; left: 49.584vw" v-if="five">河西小学</span>
      <p
        style="top: 19.37vw; left: 46.525vw; background-color: #bf9000"
        :class="eighteen ? 'active' : ''"
      >
        18
      </p>
      <span style="top: 19.241vw; left: 48.327vw" v-if="eighteen"
        >河西小学</span
      >
      <p
        style="top: 24.453vw; right: 16.86vw; background-color: #dc70bf"
        :class="eight ? 'active' : ''"
      >
        8
      </p>
      <span style="top: 24.324vw; right: 7.318vw" v-if="eight"
        >无人共享书屋</span
      >
      <p
        style="top: 24.453vw; right: 15.831vw; background-color: #8497b0"
        :class="thirteen ? 'active' : ''"
      >
        13
      </p>
      <span style="top: 24.26vw; right: 7.49vw" v-if="thirteen"
        >创想工作室</span
      >
      <p
        style="top: 25.804vw; right: 15.702vw; background-color: #8497b0"
        :class="fifteen ? 'active' : ''"
      >
        15
      </p>
      <span style="top: 25.611vw; right: 9.009vw" v-if="fifteen"
        >双创中心</span
      >
      <p
        style="top: 27.027vw; right: 15.959vw; background-color: #8497b0"
        :class="sixteen ? 'active' : ''"
      >
        16
      </p>
      <span style="top: 26.77vw; right: 4.044vw" v-if="sixteen">
        创业创新服务中心</span
      >
      <p
        style="top: 26.319vw; right: 18.083vw; background-color: #66bf1f"
        :class="eleven ? 'active' : ''"
      >
        11
      </p>
      <span style="top: 26.062vw; right: 10.013vw" v-if="eleven">
        室外运动点</span
      >
      <p
        style="top: 28.764vw; right: 18.405vw; background-color: #8497b0"
        :class="fourteen ? 'active' : ''"
      >
        14
      </p>
      <span style="top: 28.507vw; right: 11.536vw" v-if="fourteen">
        人才公寓</span
      >
      <p
        style="top: 28.571vw; right: 17.118vw; background-color: #2f5597"
        :class="twentyThree ? 'active' : ''"
      >
        23
      </p>
      <span style="top: 28.378vw; right: 7.94vw" v-if="twentyThree">
        超低能耗建筑</span
      >
      <p
        style="top: 28.829vw; right: 14.415vw; background-color: #dc70bf"
        :class="seven ? 'active' : ''"
      >
        7
      </p>
      <span style="top: 28.336vw; right: 8.027vw" v-if="seven"> 幸福学堂</span>
      <p
        style="top: 26.319vw; right: 12.806vw; background-color: #7030a0"
        :class="twentyEight ? 'active' : ''"
      >
        28
      </p>
      <span style="top: 26.19vw; right: 3.557vw" v-if="twentyEight">
        党群服务中心</span
      >
      <p
        style="top: 27.477vw; right: 12.806vw; background-color: #bf9000"
        :class="nineteen ? 'active' : ''"
      >
        19
      </p>
      <span style="top: 27.22vw; right: 0.9084vw" v-if="nineteen">
        大量建筑符合利用</span
      >
      <p
        style="top: 27.091vw; right: 11.39vw; background-color: #7030a0"
        :class="twentySeven ? 'active' : ''"
      >
        27
      </p>
      <span style="top: 26.962vw; right: 2.041vw" v-if="twentySeven">
        综合服务大厅
      </span>
      <p
        style="top: 25.868vw; right: 11.133vw; background-color: #8497b0"
        :class="thirteen ? 'active' : ''"
      >
        13
      </p>
      <span style="top: 25.739vw; right: 2.792vw" v-if="thirteen">
        创想工作室
      </span>
      <p
        style="top: 28.442vw; right: 11.133vw; background-color: #c00000"
        :class="one ? 'active' : ''"
      >
        1
      </p>
      <span style="top: 28.083vw; right: 4.199vw" v-if="one">邻里中心</span>
      <p
        style="top: 29.536vw; right: 12.291vw; background-color: #548235"
        :class="twentySix ? 'active' : ''"
      >
        26
      </p>
      <span style="top: 29.407vw; right: 1.506vw" v-if="twentySix"
        >社区邻里综合体</span
      >
      <p
        style="top: 32.303vw; right: 12.42vw; background-color: #000"
        :class="twentyOne ? 'active' : ''"
      >
        21
      </p>
      <span style="top: 32.11vw; right: 5.98vw" v-if="twentyOne">公交站点</span>
      <p
        style="top: 26.641vw; right: 9.91vw; background-color: #66bf1f"
        :class="nine ? 'active' : ''"
      >
        9
      </p>
      <span style="top: 26.448vw; right: 0.800vw" v-if="nine"
        >社区健身空间</span
      >
      <p
        style="top: 25.675vw; right: 8.43vw; background-color: #000"
        :class="twenty ? 'active' : ''"
      >
        20
      </p>
      <span style="top: 25.482vw; right: 0.753vw" v-if="twenty"
        >智慧停车场</span
      >
      <p
        style="top: 26.705vw; right: 5.856vw; background-color: #000"
        :class="twentyOne ? 'active' : ''"
      >
        21
      </p>
      <span style="top: 26.412vw; right: 7.5vw" v-if="twentyOne"
        >公交站点</span
      >
      <p
        style="top: 28.957vw; right: 8.43vw; background-color: #66bf1f"
        :class="eleven ? 'active' : ''"
      >
        11
      </p>
      <span style="top: 28.764vw; right: 0.589vw" v-if="eleven"
        >室外运动点</span
      >
      <p
        style="top: 30.437vw; right: 7.401vw; background-color: #c00000"
        :class="four ? 'active' : ''"
      >
        4
      </p>
      <span style="top: 30.308vw; right: 9.332vw" v-if="four"
        >未来生活体验中心</span
      >
      <p
        style="top: 32.11vw; right: 5.148vw; background-color: #c00000"
        :class="three ? 'active' : ''"
      >
        3
      </p>
      <span style="top: 31.917vw; right: 6.95vw" v-if="three"
        >社区入口广场</span
      >
    </div>
  </div>
</template>

<script>
import titles from "./titles.vue";
export default {
  components: { titles },
  data() {
    return {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
      eight: false,
      nine: false,
      ten: false,
      eleven: false,
      twelve: false,
      thirteen: false,
      fourteen: false,
      fifteen: false,
      sixteen: false,
      seventeen: false,
      eighteen: false,
      nineteen: false,
      twenty: false,
      twentyOne: false,
      twentyTwo: false,
      twentyThree: false,
      twentyFour: false,
      twentyFive: false,
      twentySix: false,
      twentySeven: false,
      twentyEight: false,
    };
  },
  methods: {
    mouseover(e) {
      switch (e) {
        case "one":
          this.one = true;
          break;
        case "two":
          this.two = true;
          break;
        case "three":
          this.three = true;
          break;
        case "four":
          this.four = true;
          break;
        case "five":
          this.five = true;
          break;
        case "six":
          this.six = true;
          break;
        case "seven":
          this.seven = true;
          break;
        case "eight":
          this.eight = true;
          break;
        case "nine":
          this.nine = true;
          break;
        case "ten":
          this.ten = true;
          break;
        case "eleven":
          this.eleven = true;
          break;
        case "twelve":
          this.twelve = true;
          break;
        case "thirteen":
          this.thirteen = true;
          break;
        case "fourteen":
          this.fourteen = true;
          break;
        case "fifteen":
          this.fifteen = true;
          break;
        case "sixteen":
          this.sixteen = true;
          break;
        case "seventeen":
          this.seventeen = true;
          break;
        case "eighteen":
          this.eighteen = true;
          break;
        case "nineteen":
          this.nineteen = true;
          break;
        case "twenty":
          this.twenty = true;
          break;
        case "twentyOne":
          this.twentyOne = true;
          break;
        case "twentyTwo":
          this.twentyTwo = true;
          break;
        case "twentyThree":
          this.twentyThree = true;
          break;
        case "twentyFour":
          this.twentyFour = true;
          break;
        case "twentyFive":
          this.twentyFive = true;
          break;
        case "twentySix":
          this.twentySix = true;
          break;
        case "twentySeven":
          this.twentySeven = true;
          break;
        case "twentyEight":
          this.twentyEight = true;
          break;
      }
    },
    mouseleave(e) {
      switch (e) {
        case "one":
          this.one = false;
          break;
        case "two":
          this.two = false;
          break;
        case "three":
          this.three = false;
          break;
        case "four":
          this.four = false;
          break;
        case "five":
          this.five = false;
          break;
        case "six":
          this.six = false;
          break;
        case "seven":
          this.seven = false;
          break;
        case "eight":
          this.eight = false;
          break;
        case "nine":
          this.nine = false;
          break;
        case "ten":
          this.ten = false;
          break;
        case "eleven":
          this.eleven = false;
          break;
        case "twelve":
          this.twelve = false;
          break;
        case "thirteen":
          this.thirteen = false;
          break;
        case "fourteen":
          this.fourteen = false;
          break;
        case "fifteen":
          this.fifteen = false;
          break;
        case "sixteen":
          this.sixteen = false;
          break;
        case "seventeen":
          this.seventeen = false;
          break;
        case "eighteen":
          this.eighteen = false;
          break;
        case "nineteen":
          this.nineteen = false;
          break;
        case "twenty":
          this.twenty = false;
          break;
        case "twentyOne":
          this.twentyOne = false;
          break;
        case "twentyTwo":
          this.twentyTwo = false;
          break;
        case "twentyThree":
          this.twentyThree = false;
          break;
        case "twentyFour":
          this.twentyFour = false;
          break;
        case "twentyFive":
          this.twentyFive = false;
          break;
        case "twentySix":
          this.twentySix = false;
          break;
        case "twentySeven":
          this.twentySeven = false;
          break;
        case "twentyEight":
          this.twentyEight = false;
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  position: relative;
  width: 59.331vw;
  margin-left: 6.435vw;
  z-index: 1;
  img {
    width: 42.343vw;
    height: 33.333vw;
    margin-top: 1.866vw;
    margin-right: 0.708vw;
    position: absolute;
    right: 0;
    z-index: -1;
  }
  .success {
    margin-top: 1.466vw;
    display: flex;
    margin-left: 2.574vw;
    cursor: pointer;
    .commonCss {
      .text {
        display: flex;
        align-items: center;
        margin-top: 0.322vw;
        .num {
          width: 1.161vw;
          height: 1.161vw;
          background-color: #c00000;
          border-radius: 50%;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          margin-right: 0.644vw;
          font-size: 0.774vw;
        }
        .word {
          font-weight: 600;
          font-size: 1.032vw;
        }
      }
      .title {
        font-weight: 900;
        display: flex;
        flex-direction: column;
        margin-top: 0.644vw;
        font-size: 1.032vw;
      }
    }
  }
  .p {
    p {
      width: 1.222vw;
      height: 1.222vw;
      border-radius: 50%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 1.22vw;
      font-weight: 500;
      position: absolute;
      border: 0.064vw solid #fff;
    }
    span {
      position: absolute;
      border: 0.129vw solid #1492ff;
      color: #1492ff;
      background-color: #fff;
      z-index: 5;
      font-size: 1.032vw;
      padding: 0.064vw 0.193vw;
    }
    .active {
      transform: scale(1.5, 1.5);
      z-index: 10;
    }
  }
}
</style>