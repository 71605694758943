<template>
  <div class="left">
    <div class="public">
      <titles>河西未来社区建设概况</titles>
      <div
        class="text"
        :class="more ? 'more' : 'moreR'"
        style="margin-top: 1.416vw; word-break: break-all; width: 99%"
      >
        本次社区创建类型为拆改结合类，通过拆解老旧建筑，建设未来社区，提升老城品质。其中规划单元面积为60.44公顷，实施单元面积为18.28公顷，拆除区域主要为台州老家私城和周边城中村。社区受益居民数5080人，其中拆改直接受益人数3505人，新居民1575人（含新人才300人）。项目总投资20.76亿元，计划今年8月底完成河西新村数字化改造，年底前完成部分拆迁与改造项目，2023年6月前全面完成。
      </div>
      <!-- <div
        style="float: right; font-size: 1.032vw"
        class="moreClick"
        @click="handleMore"
      >
        {{ more ? "显示更多" : "收起" }}
      </div> -->
    </div>
    <div class="public">
      <titles>本月工作安排</titles>
      <div class="text" style="margin-top: 1.416vw">
        1、台州老家私城以及河西巷南片区用地征收
      </div>
      <div class="text">2、投资模式论证、比选、决策</div>
      <div class="text">
        3、路桥区河西未来社区智慧平台功能完善，新增直播功能
      </div>
    </div>
    <div class="activity">
      <titles>本月活动</titles>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 1.673vw;
          font-size: 1.032vw;
          margin-top: 1.484vw;
          margin-bottom: 1.094vw;
        "
      >
        <div>
          <div class="text">1、党建活动</div>
          <div class="text">2、志愿者服务</div>
          <div class="text">3、羽毛球比赛</div>
        </div>
        <div>
          <div class="text">1/1次</div>
          <div class="text">3/5次</div>
          <div class="text">1/1次</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titles from "../components/titles.vue";

export default {
  components: { titles },
  data() {
    return {
      more: true,
    };
  },
  methods: {
    handleMore() {
      console.log(this.more);
      this.more = !this.more;
    },
  },
};
</script>

<style lang="less" scoped>
.left {
  width: 31.873vw;
  .public {
    .text {
      width: 29.858vw;
      margin-left: 1.673vw;
      font-size: 1.032vw;
      margin-top: 0.08vw;
    }
    .more {
      height: 12.258vw;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 5;
    }
    .moreR {
      height: 25.516vw;
    }
    .moreClick:hover {
      cursor: pointer;
    }
  }
}
</style>