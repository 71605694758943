<template>
  <div id="twChart" ref="chart"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  mounted() {
    this.init();
  },
  data() {
    return {
      chart: null,
    };
  },
  props: ["pass", "num"],
  watch: {
    num() {
      this.chart.dispose();
      this.init();
    },
  },
  methods: {
    nowSize(val, initWidth = 1554) {
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },
    init() {
      this.chart = echarts.init(this.$refs.chart);
      let option = {
        grid: [
          {
            left: "15%",
          },
        ],
        color: this.pass.color,
        xAxis: {
          type: "category",
          data: this.pass.total,
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },
        yAxis: {
          type: "value",
          splitNumber: 3,
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },

        tooltip: {},
        series: [
          {
            data: this.pass.current,
            type: "bar",
            barWidth: 20,
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
  },
};
</script>

<style lang="less" scoped>
</style>