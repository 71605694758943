<template>
  <div style="height: 100vh" v-if="show">
    <div class="nav">
      <div
        :class="navindex == 0 ? 'nav_item active' : 'nav_item'"
        @click="changenav(0)"
      >
        河西未来社区
      </div>
      <div
        :class="navindex == 1 ? 'nav_item active' : 'nav_item'"
        @click="changenav(1)"
      >
        凤栖未来社区
      </div>
    </div>

    <div v-if="navindex == 0">
      <div class="indextitle">
        <div class="it_first">
          <div class="itf_img"><img src="../assets/01.png" alt="" /></div>
          <div class="itf_txt">
            <div class="title_nav">项目意义</div>
            <div class="itf_t">
              未来社区是推进台州老城活力有机更新建设的标志性项目。未来社区的建设将起到改善民生，驱动投资，带动周边产业，促进周边业态转型的积极作用。同时开展未来社区试点是实现人民对美好生活向往的重要举措。台州位干浙江沿海中部，中国黄金海岸中段，境域东濒东海，南接温岭，西邻黄岩，北连柳江，路桥区作为台州中心城市重要组成部分，在市区融合发展、提高中心城市首位度等方面的重要性不言而喻。近年来，路桥区围绕“三个率先”奋斗目标，克难攻坚，砥砺前行，城市建设和发展面貌日新月异。今后一个时期，路桥区将遵循“一尊重五统筹”的城市工作思路，以“山海水城”“和合圣地”“制造之都”为目标，进一步找准定位，发挥优势通过对未来毗邻的核心商贸区的衔接及未来社区的打造及相互融合，从而促进城市、文化、社会和经济的可持续发展以及国际化知名社区的营造，从而打造国际化世界特色文化名城。
            </div>
          </div>
        </div>
        <div class="it_second">
          <div class="title_nav">项目概况</div>
        </div>
        <div class="it_shree">
          <div
            :class="item.type == 2 ? 'its_item its_item_active' : 'its_item'"
            its_item
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="itsi_top">{{ item.txt1 }}</div>
            <div
              :class="
                item.type == 2
                  ? 'itsi_bottom itsi_bottom_active'
                  : 'itsi_bottom'
              "
            >
              {{ item.txt2 }}
            </div>
          </div>
        </div>
      </div>

      <div class="xmys">
        <div class="xmys_left">
          <div class="xmys_nav">项目优势</div>
          <div class="xmys_txt">
            <div>项目基础条件与未来社区建设要求高度契合</div>
            <div>(1)居民改造更新意愿高、启动较快、资金易平衡</div>
            <div>
              (2)基地位置位于老城中心，周边有中盛商圈、十里长街、会展中心等公共设施
            </div>
            <div>
              (3)依托路桥核心商贸区，利于打造商业+社区的新型人文、商业模式;
            </div>
            <div>
              (4)中盛商圈、十里长街、会展中心等商贸、旅游资源丰富、人才集聚效应显著，配套设施齐全;
            </div>
            <div>(5)毗邻台州母亲河-南官河，利于水韵空间的营造;</div>
          </div>
        </div>
        <div class="xmys_right" style="margin-right: 10px">
          <div class="xmys_nav">路径措施</div>
          <div class="xmys_right_txt">
            <div>
              (1)项目将以政策引导、市场化运作方式，全面推进“路桥未来社区”区块的拆改结合城市更新改造，打造成为路桥老城现代产业、现代城市、城市文化、自然生态“四个体系”建设的标杆示范项目。
            </div>
            <div>
              (2)通过机构顶层设计、各部门协同、体制机制配套、舆论氛围打造等举措推动未来社区试点建设。
            </div>
          </div>
          <div class="xmys_nav">项目亮点</div>
          <div class="xmys_right_txt">
            <div>古新共荣、商居共乐、生态共生</div>
          </div>
        </div>
      </div>

      <div class="yjmb">
        <div class="xmys_nav">愿景目标</div>
        <div class="yjmb_wrap">
          <div class="yjmb_item">
            <div class="yi_title">古韵今生</div>
            <div class="yi_txt">
              打造路桥十里长街商贸文化新起点，实现老城核心居住创业活力新复苏生态，文化，宜居。
            </div>
          </div>
          <div class="yjmb_item">
            <div class="yi_title">老城新颜</div>
            <div class="yi_txt">
              “局部改造、有机更新”方式提升现状建筑，严格控制引导新建建筑风貌与老城的和谐统一。
            </div>
          </div>
          <div class="yjmb_item">
            <div class="yi_title">商居样板</div>
            <div class="yi_txt">
              打造“傍水南官新商居，长街老城复繁华”的生活愿景。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="navindex == 1">
      <div class="fqwlsq">
        <div class="fqwlsq_top">
          <div class="fqwlsq_topitem">
            <img src="../assets/02.png" alt="" />
          </div>
          <div class="fqwlsq_topitem">
            <div class="title_nav">概况</div>
            <div class="fqwlsq_topitem_txt">
              项目规划单元155公顷，其中实施单元可建设用地28.3公顷，主要建设项目包括凤栖社区安置回迁、人才住宅、TOD邻里综合体、幼儿园，以及部分商品房开发项目。项目总投资约82.83亿元。
            </div>
          </div>
          <div class="fqwlsq_topitem">
            <div class="title_nav">项目优势</div>
            <div class="fqwlsq_topitem_txt">
              <div>(一)区位优越，交通便捷;</div>
              <div>(二)配套完善，人才集聚;</div>
              <div>(三)各方关注，对接积极</div>
            </div>
          </div>
        </div>

        <div class="fqwlsq_top">

          <div class="fqwlsq_topitem">
            <div class="title_nav" style="margin-left:0">试点创建意义</div>
            <div class="fqwlsq_topitem_txt" style="margin-left:0">凤栖社区项目处于路桥城市核心拓展片区，周围商贸物流资源丰富，轨道交通便利，生态环境良好，该未来社区的创建既是路桥新城拓展的西南门户，也是着力打造浙东南发展的西进标杆和台州南部的生态之城。凤栖社区的建设能提升群众获得感、幸福感、安全感，引领片区乃至台州中心城区的整体发展，推动城市面貌华丽“蝶变”，极具示范意义。</div>
          </div>

          <div class="fqwlsq_topitem">
            <div class="title_nav">试点创建亮点</div>
            <div class="fqwlsq_topitem_txt">
              <div>传承台州文化，立足激发城市焕新和改善民生的创新式邻里社区</div>
              <div>(一)依托“共享交往+市井街巷”打造多元温馨未来邻里场景</div>
              <div>(二)依托“全龄覆盖+数字平台”打造跨龄互动未来教育场景</div>
              <div>(三)依托“安全适宜+立体复合”打造无缝便捷未来交通场景</div>
              <div>(四)依托“创业平台+人才公寓”打造青春活力未来创业场景</div>
            </div>
          </div>

          <div class="fqwlsq_topitem">
            <div class="title_nav">目标定位</div>
            <div class="fqwlsq_topitem_txt">
              <div>总体定位:台州品质住区新标杆、新城跨越发展新引擎、凤栖居民共享新家园</div>
              <div>目标:浙东南发展的西进标杆、台州南部的生态之城:浙江省未来社区试点标杆。</div>
            </div>
          </div>

        </div>
      </div>

      <div class="sdlx">
        <div class="sdlx_left">
          <div class="title_nav">试点类型</div>
          <div class="fqwlsq_topitem_txt">改造更新类</div>
        </div>
        <div class="sdlx_right">
          <div class="title_nav" style="margin-left:0">试点类型</div>
          <div class="it_shree2">
            <div
            :class="item.type == 2 ? 'its_item2 its_item_active' : 'its_item2'"
            its_item2
            v-for="(item, index) in list2"
            :key="index"
          >
            <div class="itsi_top">{{ item.txt1 }}</div>
            <div
              :class="
                item.type == 2
                  ? 'itsi_bottom itsi_bottom_active'
                  : 'itsi_bottom'
              "
            >
              {{ item.txt2 }}
            </div>
          </div>
          </div>
        </div>
      </div>

    </div>

    <div class="common" style="background-color: #fff">
      <information></information>
      <cityMap></cityMap>
    </div>
    <div class="common">
      <platform :num="num"></platform>
      <div class="right" style="width: 63.256vw">
        <column :num="num"></column>
        <cake :num="num"></cake>
      </div>
    </div>
  </div>
</template>

<script>
import titles from "../components/titles.vue";
import information from "../components/information.vue";
import cityMap from "../components/cityMap.vue";
import platform from "../components/platform.vue";
import column from "../components/column.vue";
import cake from "../components/cake.vue";
export default {
  components: { titles, information, cityMap, platform, column, cake },
  data() {
    return {
      num: 0,
      show: true,
      navindex: 0,
      list: [
        {
          type: 1,
          txt1: "项目总投资",
          txt2: "29.10亿元",
        },
        {
          type: 2,
          txt1: "规划单元面积",
          txt2: "60.44公顷",
        },
        {
          type: 1,
          txt1: "实施单元面积",
          txt2: "18.25公顷",
        },
        {
          type: 2,
          txt1: "直接受益居民数",
          txt2: "3763人",
        },
        {
          type: 1,
          txt1: "新建部分新居民数",
          txt2: "1260人",
        },
        {
          type: 2,
          txt1: "引进各类人才数",
          txt2: "78人",
        },
      ],
      list2: [
        {
          type: 1,
          txt1: "综合(核心)指标",
          txt2: "7675人",
        },
        {
          type: 2,
          txt1: "直接受益居民数",
          txt2: "3997人+房票1379人",
        },
        {
          type: 1,
          txt1: "引进各类人才数",
          txt2: "2300人",
        },
        {
          type: 2,
          txt1: "房票",
          txt2: "394 张，1379 人",
        },
        {
          type: 1,
          txt1: "项目实施计划",
          txt2: "整体建设将在3年左右完成",
        },
      ],
    };
  },
  created() {
    const isZzd = JSON.stringify(navigator.userAgent).indexOf("TaurusApp") > -1;
    if (isZzd) {
      this.listenResize();
      this.body = isZzd;
    }
    else {
      window.location.href =
        "https://dgov-integrate.jinhua.gov.cn:5443/szjh-gov-pc/#/404";
    }
  },
  methods: {
    listenResize() {
      setTimeout(() => {
        window.onresize = () => {
          this.num++;
        };
      }, 0);
    },
    changenav(e) {
      console.log(e);
      this.navindex = e;
    },
  },
};
</script>

<style lang="less">
.common {
  display: flex;
  margin: 1.287vw;
  width: 97.105vw;
  padding-bottom: 1.935vw;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.287vw;
  width: 97.105vw;
}
.nav_item {
  width: 49%;
  height: 5vw;
  background-color: #fff;
  text-align: center;
  line-height: 5vw;
  color: #acacac;
}
.active {
  color: #1492ff;
  border-bottom: 2px solid #1492ff;
}
.indextitle {
  margin: 1.287vw;
  width: 97.105vw;
  // height: 433px;
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 1.287vw;
}
.it_first {
  margin: 20px;
  display: flex;
  align-items: center;
}
.itf_img {
  width: 339px;
  height: 227px;
}
.itf_img img {
  width: 339px;
  height: 227px;
}
.itf_txt {
  height: 227px;
}
.title_nav {
  padding-left: 1.287vw;
  border-left: 0.515vw solid #1492ff;
  color: #1492ff;
  font-size: 1.032vw;
  margin-left: 1.673vw;
  // margin-top: 1.48vw;
}
.title_nav::selection {
  color: #fff;
  background: #1890ff;
}
.itf_t {
  margin-left: 1.673vw;
  margin-top: 1.48vw;
  font-size: 1.032vw;
}
.it_shree {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1.8vw;
}
.it_shree2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.278vw;
}
.its_item {
  width: 15%;
  height: 72px;
  color: #000000;
  background-color: #ddeefe;
  text-align: center;
}
.its_item2 {
  width: 18%;
  height: 72px;
  color: #000000;
  background-color: #ddeefe;
  text-align: center;
}
.its_item2:nth-last-child(1) {
  margin-right: 1.278vw;
}
.itsi_top {
  margin-top: 10px;
}
.itsi_bottom {
  margin-top: 10px;
  color: #1492ff;
}
.its_item_active {
  background-color: #fef0e5;
}
.itsi_bottom_active {
  margin-top: 10px;
  color: #ed7d31;
}
.xmys {
  margin: 1.287vw;
  width: 97.105vw;
  height: 290px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.xmys_left {
  width: 49%;
}
.xmys_right {
  width: 49%;
}
.xmys_nav {
  padding-left: 1.287vw;
  border-left: 0.515vw solid #1492ff;
  color: #1492ff;
  font-size: 1.032vw;
  margin-left: 1.673vw;
  margin-top: 1.48vw;
}
.xmys_nav::selection {
  color: #fff;
  background: #1890ff;
}
.xmys_txt {
  margin-top: 1.48vw;
  margin-left: 3.5vw;
}
.xmys_right_txt {
  margin-top: 1.48vw;
  margin-left: 1.673vw;
}
.yjmb {
  margin: 1.287vw;
  width: 97.105vw;
  // height: 200px;
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 1.278vw;
}
.yjmb_wrap {
  display: flex;
  justify-content: space-evenly;
}
.yjmb_item {
  width: 31%;
  margin-top: 1.8vw;
}
.yi_title {
  height: 42px;
  background-color: #ddeefe;
  color: #000000;
  text-align: center;
  line-height: 42px;
}
.yi_txt {
  height: 70px;
  border-left: 1px dashed #ddeefe;
  border-right: 1px dashed #ddeefe;
  border-bottom: 1px dashed #ddeefe;
  padding: 10px;
}

.fqwlsq {
  margin: 1.287vw;
  width: 97.105vw;
  background-color: #fff;
  overflow: hidden;
  // padding-bottom: 1.287vw;
}
.fqwlsq_top {
  display: flex;
  justify-content: space-between;
  margin-top: 1.287vw;
  margin-left: 1.287vw;
}
.fqwlsq_topitem {
  width: 32%;
  height: 227px;
  padding-right: 1.278vw;
}
.fqwlsq_topitem img {
  width: 339px;
  height: 227px;
}
.fqwlsq_topitem_txt {
  margin-left: 1.673vw;
  margin-top: 1.48vw;
}
.sdlx{
  margin: 1.287vw;
  width: 97.105vw;
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 1.287vw;
  display: flex;
  justify-content: space-between;
}
.sdlx_left{
  width: 18%;
  margin-top: 1.278vw;
}
.sdlx_right{
  width: 80%;
  margin-top: 1.278vw;
}
</style>
