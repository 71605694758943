<template>
  <div class="content">
    <div>
      <titles>网格工作</titles>
      <chart
        :pass="work"
        :num="num"
        style="width: 30.035vw; height: 29.215vw"
      ></chart>
    </div>
    <div>
      <titles>社区高频应用场景</titles>
      <chart
        :pass="visit"
        :num="num"
        style="width: 30.035vw; height: 29.215vw"
      ></chart>
    </div>
  </div>
</template>

<script>
import titles from "./titles.vue";
import chart from "./chart.vue";
export default {
  components: { titles, chart },
  props: ["num"],
  data() {
    return {
      work: {
        total: ["巡查走访", "矛盾调解", "民生服务", "安全隐患排查"],
        current: [873, 325, 533, 221],
        color: ["#ED7D31"],
      },
      visit: {
        total: ["创业","教育","健康","邻里"],
        current: [338,451,476,221],
        color: ["#1492FF"],
      },
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  margin-left: 1.287vw;
  display: flex;
  background-color: #fff;
}
</style>