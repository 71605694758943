<template>
  <div>
    <div class="cake">
      <div>
        <titles>社区人口概况</titles>
        <div
          id="twChart"
          ref="chart"
          style="width: 25.74vw; height: 23.199vw"
        ></div>
      </div>
      <div>
        <titles>就业年龄段的就业行业分布</titles>
        <div class="layout" style="padding: 0 1.931vw; width: 36.165vw">
          <div class="industry">
            <div>
              <div>政府机关</div>
              <p>105</p>
              <img src="../assets/组.png" alt="" />
            </div>
            <div>
              <div>餐饮业</div>
              <p>522</p>
              <img src="../assets/组.png" alt="" />
            </div>
            <div>
              <div>服装业</div>
              <p>212</p>
              <img src="../assets/组.png" alt="" />
            </div>
          </div>
          <div class="industry">
            <div>
              <div>零售业</div>
              <p>601</p>
              <img src="../assets/组.png" alt="" />
            </div>
            <div>
              <div>制造业</div>
              <p>155</p>
              <img src="../assets/组.png" alt="" />
            </div>
            <div>
              <div>服务业</div>
              <p>233</p>
              <img src="../assets/组.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titles from "../components/titles.vue";
import * as echarts from "echarts";

export default {
  components: { titles },
  props: ["num"],
  mounted() {
    this.init();
  },
  watch: {
    num() {
      this.chart.dispose();
      this.init();
    },
  },
  methods: {
    nowSize(val, initWidth = 1554) {
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },

    init() {
      this.chart = echarts.init(this.$refs.chart);
      let option = {
        color: ["#5B9BD5", "#ED7D31"],
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          bottom: 0,
          textStyle: {
            fontSize: this.nowSize(16),
          },
        },
        series: [
          {
            name: "人口概况",
            type: "pie",
            radius: "50%",
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              formatter: "{b}{c} 次",
            },
            data: [
              { name: "流动人口（次）", value: 843 },
              { name: "户籍人口（次）", value: 2662 },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                // shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
  },
};
</script>

<style lang="less" scoped>
.cake {
  display: flex;
  background-color: #fff;
  margin-left: 1.287vw;
  margin-top: 1.287vw;
  height: 36.342vw;
}
.industry {
  display: flex;
  justify-content: space-between;
  font-size: 1.032vw;
  position: relative;
  margin-top: 1.931vw;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.931vw;
  }
  img {
    width: 8.044vw;
    height: 3.861vw;
  }
  p {
    position: absolute;
    top: 5.663vw;
  }
}
</style>