<template>
  <div style="width: 34.234vw; background-color: #fff">
    <titles>河西未来社区智慧平台</titles>
    <div style="padding: 0 1.287vw">
      <div class="matter">
        <div class="title">本月热门事项</div>
        <div class="num">
          <div>21</div>
          <div>13</div>
          <div>10</div>
        </div>
        <div class="text">
          <div>创业指导</div>
          <div>发布招租</div>
          <div>贷款咨询</div>
        </div>
      </div>
      <div class="number">
        <div class="title">总注册人数</div>
        <chart
          :pass="person"
          :num="num"
          style="width: 31.274vw; height: 21.879vw"
        ></chart>
      </div>
      <div class="number">
        <div class="title">每周访问统计</div>
        <chart
          :pass="visit"
          :num="num"
          style="width: 31.274vw; height: 21.879vw"
        ></chart>
      </div>
    </div>
  </div>
</template>

<script>
import titles from "./titles.vue";
import chart from "./chart.vue";
export default {
  components: { titles, chart },
  props: ["num"],
  data() {
    return {
      person: {
        total: [7.5, 7.12, 7.19, 7.26],
        current: [32, 45, 66, 76],
        color: ["#DDEEFE"],
      },
      visit: {
        total: [7.5, 7.12, 7.19, 7.26],
        current: [40, 73, 102, 123],
        color: ["#FEF0E5"],
      },
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.matter {
  display: flex;
  flex-direction: column;
  margin-top: 1.416vw;
  border: 0.097vw solid #eee;
  width: 31.274vw;
  .title {
    margin-top: 0.965vw;
    margin-left: 1.287vw;
    font-size: 1.032vw;
  }
  .num {
    display: flex;
    justify-content: space-between;
    margin-top: 0.837vw;
    font-weight: 900;
    color: #1492ff;
    padding: 0 2.574vw;
    font-size: 1.032vw;
  }
  .text {
    display: flex;
    justify-content: space-between;
    margin-top: 0.45vw;
    margin-bottom: 0.837vw;
    padding: 0 1.287vw;
    font-size: 1.032vw;
  }
}
.number {
  width: 31.274vw;
  border: 0.097vw solid #eee;
  margin-top: 1.287vw;
  .title {
    margin-top: 0.965vw;
    margin-left: 1.287vw;
    font-size: 1.032vw;
  }
}
</style>