<template>
  <div>
    <div class="title">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.title {
  padding-left: 1.287vw;
  border-left: 0.515vw solid #1492ff;
  color: #1492ff;
  font-size: 1.032vw;
  margin-left: 1.673vw;
  margin-top: 1.48vw;
}
</style>